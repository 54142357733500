var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("img", {
    class: { image: true, fadein: _vm.loaded, loading: !_vm.loaded },
    attrs: { src: _vm.imagePath, alt: "image", draggable: "false" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }